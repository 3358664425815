
import { Component, Vue } from 'nuxt-property-decorator'
import WorkCard from './WorkCard.vue'

@Component({
  components: {
    WorkCard
  }
})
export default class extends Vue {}


import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { WorkCardModel } from '~/model/'
import BaseYavagroWorkCard from '~/components/UI/BaseYavagroWorkCard.vue'

@Component({
  components: {
    BaseYavagroWorkCard
  }
})
export default class extends Vue {
  @Prop({
    default: () => ({
      name: '',
      number: 0,
      description: '',
      image: ''
    })
  })
  readonly cardInfo!: WorkCardModel
}

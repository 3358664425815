
import hexagon from '../../static/icons/ui/hexagon.svg'

export default {
  name: 'BaseYavagroWorkCard',
  props: {
    name: {
      type: String,
      required: true
    },
    image: {
      type: String,
      default: ''
    },
    href: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    number: {
      type: Number,
      default: 0
    }
  },
  methods: {
    getPath() {
      return hexagon
    }
  }
}
